<script>
import HallForm from "@/views/menu/hall/hallForm";
import Component, { mixins } from "vue-class-component";
import { toastService } from "@/services/toastService.js";

@Component({
  inject: {
    hallService: "hallService",
    fileUploadService: "fileUploadService",
  }
})
export default class HallClone extends mixins(HallForm) {
  formId = "clone-hall-form";

  get decoratedElements() {
    return this.insertBefore(
      this.elements,
      {
        legend: this.translations.labels.stand_form_itemsToDuplicate,
        type: "fieldset",
        group: "default",
        collapsible: true,
        open: true,
        resetColumns: true,
        elements: [
          {
            id: "hasInfobox",
            label: this.translations.labels.hall_form_infobox,
            type: "checkbox",
            required: false,
          },
          {
            id: "hasLink",
            label: this.translations.labels.hall_form_link,
            type: "checkbox",
            required: false,
          }
        ]
      },
    );
  }

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        disabled: () => {
          return this.fileUploadService.isUploading();
        },
        handle: this.save,
      },
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
        await this.hallService.clone(this.model.id, {
        code: this.model.code,
        idEvent: this.model.idEvent,
        hasInfoBox: this.model.hasInfobox,
        hasLink: this.model.hasLink,
        localizations: this.model.localizations,
      });
       this.$router.push("/hall/list");
      toastService.success(this.translations.success.hall_clone);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle() {
    return this.translations.pageTitles.halls_clone;
  }

  async afterCreate () {
    this.model = await this.hallService.read(this.$route.params.id);
    this.isReady = true;
  }
}
</script>
